export const levels = {
  beginner: {
    label: 'Beginner',
    cols: 10,
    rows: 10,
    mines: 10,
    flags: 10,
  },
  intermediate: {
    label: 'Intermediate',
    cols: 16,
    rows: 16,
    mines: 40,
    flags: 40,
  },
  expert: {
    label: 'Expert',
    cols: 30,
    rows: 16,
    mines: 99,
    flags: 99,
  },
};

export const emojis = {
  win: '😎',
  lose: '😵',
  happy: '🙂',
  scared: '😮',
  rigged: '💣',
  flagged: '⛳️',
  exploded: '💥',
  wrong: '😛',
};