import styled from 'styled-components';

export default styled(props => <div {...props}>
  <img src="favicon-512x512.png" alt="Minesweeper Icon" width="24" height="24" />
  <h1>Minesweeper</h1>
  <button aria-label="minimize"></button>
  <button aria-label="maximize"></button>
  <button aria-label="close"></button>
</div>)`
  display: flex;
  align-items: center;
  text-align: center;
  height: 1.75rem;
  padding: .125rem .25rem;
  background: linear-gradient(to right, #09246A, #ABCAEB);
  img {
    margin-right: .25rem;
  }
  h1 {
    font-size: 1rem;
    margin-right: auto;
    color: var(--light);
  }
  button {
    height: 1.5rem;
    width: 1.5rem;
    background: var(--light-gray);
    border: 2px var(--outset);
    &:active {
      border: 2px var(--inset);
    }
    margin-left: .125rem;
    &::before, &[aria-label=close]::after {
      position: absolute;
      content: '';
    }
    &[aria-label=minimize]::before {
      width: 50%;
      bottom: 3px;
      border-bottom: 2px solid var(--dark);
    }
    &[aria-label=maximize]::before {
      top: .25rem;
      bottom: .25rem;
      width: .875rem;
      border: solid var(--dark);
      border-width: 2px 1px 1px;
    }
    &[aria-label=close] {
      margin-left: .25rem;
      &::before, &::after {
        height: 2px;
        width: 1rem;
        background: var(--dark);
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
`;