import styled from 'styled-components';
import TitleBar from './components/TitleBar';
import MenuBar from './components/MenuBar';
import ScoreBoard from './components/ScoreBoard';
import MineField from './components/MineField';

const Game = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 3.75rem);
  overflow: hidden;
  padding: .5rem;
  background: var(--light-gray);
  border: .25rem outset var(--light);
`;

function App() {
  return <>
    <TitleBar />
    <MenuBar />
    <Game>
      <ScoreBoard />
      <MineField />
    </Game>
  </>;
}

export default App;
