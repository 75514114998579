import { useContext } from 'react';
import styled from 'styled-components';
import Context from '../context';
import { emojis } from '../config';

export default styled(props => {
  const { context: { status, emoji }, dispatch } = useContext(Context);
  return <button {...props} onClick={() => dispatch({type: 'reset'})}>
    {emojis[status] || emojis[emoji]}
  </button>;
})`
  position: relative;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  background: var(--light-gray);
  outline: 1px solid var(--gray);
  border: var(--outset);
  &:active {
    border: var(--inset);
  }
`;