import { useContext, useState, useEffect } from 'react';
import Context from '../context';
import styled from 'styled-components';
import { emojis } from '../config';

const Grid = styled(({cols, ...props}) => <div {...props}/>)`
  display: grid;
  grid-template-columns: repeat(${({cols}) => cols}, 1fr);
  border: var(--inset);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: rgba(0, 0, 0, .4);
  }
`;

const Cell = styled(({count, ...props}) => <button {...props} />)`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: var(--outset);
  font-family: Menlo, monospace;
  font-weight: bold;
  font-size: 22px;
  ${({count}) => count ? `
    color: var(--${['dark', 'blue', 'green', 'red', 'dark-blue', 'dark-red', 'light-blue', 'dark', 'gray'][count]});
    text-shadow: 2px 0 0 currentColor;
  ` : ''}
  &.active, &:active {
    border: 1px solid var(--gray);
    border-width: 1px 0 0 1px;
  }
`;

function random(cells) {
  return Math.floor(Math.random() * cells);
}

function getArea(i, rows, cols) {
  // within cells, interlinked
  const col = i % cols;
  const row = Math.floor(i / rows);
  const n = [];
  if (col > 0 && row > 0) n.push(i - cols - 1);
  if (row > 0) n.push(i - cols);
  if (col < (cols - 1) && row > 0) n.push(i - cols + 1);
  if (col > 0) n.push(i - 1);
  if (col < (cols - 1)) n.push(i + 1);
  if (col > 0 && row < (rows - 1)) n.push(i + cols - 1);
  if (row < (rows - 1)) n.push(i + cols);
  if (col < (cols - 1) && row < (rows - 1)) n.push(i + cols + 1);
  return n;
}

function getMines(mines, cells) {
  return [...Array(mines)].reduce(a => {
    let i = random(cells);
    while (i in a) i = random(cells);
    a[i] = 1;
    return a;
  }, {});
}

function getValue(cell) {
  if (cell.wrong) return emojis.wrong;
  if (cell.exploded) return emojis.exploded;
  if (cell.flagged) return emojis.flagged;
  if (cell.rigged) return emojis.rigged;
  if (cell.count > 0) return cell.count;
}

function initField(mines, rows, cols) {
  const cells = rows * cols;
  const m = getMines(mines, cells);
  return [...Array(cells).keys()].reduce((a, i) => {
    const area = getArea(i, rows, cols);
    a[i] = {
      area,
      rigged: !!m[i],
      flagged: false,
      active: false,
      exploded: false,
      wrong: false,
      count: area.reduce((a, i) => {
        if (m[i]) a++;
        return a;
      }, 0),
    };
    return a;
  }, {});
}

function MineField() {
  const { context: { status, rows, cols, mines, flags }, dispatch } = useContext(Context);
  const [state, setState] = useState(initField(mines, rows, cols));

  function showAll() {
    const field = { ...state };
    Object.values(field).forEach(cell => {
      cell.active = true
      if (cell.flagged && !cell.rigged) cell.wrong = true;
    });
    setState(field);
  }

  function showArea(area) {
    const field = area.reduce((a, i) => {
      const cell = a[i];
      if (!cell.active && !cell.rigged) {
        cell.active = true;
        !cell.count && showArea(cell.area);
      }
      return a;
    }, { ...state });
    setState(field);
  }

  function showCell(i) {
    if (status === 'win' || status === 'lose') return;
    status !== 'running' && dispatch({status: 'running'});
    const field = { ...state };
    const cell = field[i];
    switch (true) {
      case cell.active:
      case cell.flagged:
        return;
      case cell.rigged:
        dispatch({emoji: 'lose', status: 'lose'});
        cell.exploded = true;
        showAll();
        return;
      default:
        !cell.count && showArea(cell.area);
        break;
    }
    cell.active = true;
    checkStatus(field);
  }

  function toggleFlag(i) {
    if (status === 'win' || status === 'lose') return;
    status !== 'running' && dispatch({status: 'running'});
    const field = { ...state };
    const cell = field[i];
    if (cell.active) return;
    if (cell.flagged || flags) {
      cell.flagged = !cell.flagged;
      checkStatus(field);
      dispatch({flags: flags + (cell.flagged ? -1 : 1)});
    }
  }

  function checkStatus(field) {
    const cells = rows * cols;
    let found = 0;
    let active = 0;
    Object.values(field).forEach(i => {
      if (i.flagged && i.rigged) found++;
      if (i.active) active++;
    });
    if (active + found === cells && found === mines) {
      showAll();
      dispatch({emoji: 'win', status: 'win'});
    } else {
      setState(field);
    }
  }

  useEffect(() => {
    status === 'new' && setState(initField(mines, rows, cols));
  }, [status, mines, rows, cols]);

  return <Grid cols={cols}>
    {Object.entries(state).map(([i, cell]) => {
      const value = getValue(cell);
      const { active, flagged } = cell;
      return <Cell
        key={i}
        className={active ? 'active' : ''}
        count={typeof value === 'number' ? value : null}
        onMouseDown={() => dispatch({emoji: 'scared'})}
        onMouseUp={() => dispatch({emoji: 'happy'})}
        onClick={() => showCell(i)}
        onContextMenu={e => { e.preventDefault(); toggleFlag(i); }}
      >
      {(active || flagged) && value}
    </Cell>;
    })}
  </Grid>;
}

export default MineField;