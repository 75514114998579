import { useContext, useState, useEffect } from 'react';
import Context from '../context';
import styled from 'styled-components';
import Emoji from './Emoji';
import Display from './Display';

export default styled(props => {
  const { context: { status, flags }} = useContext(Context);
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timeOut = setTimeout(() => setTime(time + 1), 1e3);
    if (status !== 'running') clearTimeout(timeOut);
    if (time === 999) setTime(0);
    return () => clearTimeout(timeOut);
  }, [status, time]);

  useEffect(() => {
    status === 'new' && setTime(0);
  }, [status]);

  return <div {...props}>
    <Display value={flags} />
    <Emoji />
    <Display value={time} />
  </div>;
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: .25rem;
  margin-bottom: .5rem;
  border: var(--inset);
`;