import { createContext, useReducer } from 'react';
import { levels } from './config';

const Context = createContext();
const { Consumer } = Context;

const initialState = {
  emoji: 'happy',
  status: 'new',
};

const state = {
  ...initialState,
  level: 'beginner',
  ...levels.beginner,
};

function reducer(state, action) {
  switch (action.type) {
    case 'reset':
      return { ...state, ...initialState, ...levels[state.level] };
    case 'setLevel':
      return { ...initialState, level: action.level, ...levels[action.level] };
    default:
      return { ...state, ...action };
  }
}

function Provider(props) {
  const [context, dispatch] = useReducer(reducer, { ...state, ...props.value });
  return (
    <Context.Provider value={{ context, dispatch }}>
      {props.children}
    </Context.Provider>
  );
}

export { Provider, Consumer };
export default Context;