import styled from 'styled-components';

export const Digit = styled(props => <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.5 8.61" data-testid="digit">
  <path className="a" d="M.16 0 1.4 1.22h1.72L4.35 0z"/>
  <path className="b" d="M4.5 4.31 3.28 3.06V1.34L4.5.12z"/>
  <path className="c" d="M4.5 8.5 3.28 7.25V5.53L4.5 4.31z"/>
  <path className="d" d="M.16 8.61 1.4 7.4h1.72l1.23 1.21z"/>
  <path className="e" d="m0 8.5 1.22-1.25V5.53L0 4.31z"/>
  <path className="f" d="m0 4.31 1.22-1.25V1.34L0 .12z"/>
  <path className="g" d="m.16 4.28 1.24.7h1.72l1.23-.7-1.23-.71H1.4l-1.24.71h3.91z"/>
</svg>)`
  height: 100%;
  fill: var(--dark-red);
  &.d0 *:not(.g),
  &.d1 .b, &.d1 .c,
  &.d2 .a, &.d2 .b, &.d2 .d, &.d2 .e, &.d2 .g,
  &.d3 .a, &.d3 .b, &.d3 .c, &.d3 .d, &.d3 .g,
  &.d4 .b, &.d4 .c, &.d4 .f, &.d4 .g,
  &.d5 .a, &.d5 .c, &.d5 .d, &.d5 .f, &.d5 .g,
  &.d6 *:not(.b),
  &.d7 .a, &.d7 .b, &.d7 .c,
  &.d8 *,
  &.d9 .a, &.d9 .b, &.d9 .c, &.d9 .f, &.d9 .g { fill: var(--red); }
`;

const Display = styled(({value = 0, ...props}) => {
  const digits = value.toString().padStart(3, 0).split('');
  return <div {...props} aria-label={value} data-testid="display">
    {digits.map((v, i) => <Digit key={i} className={`d${parseInt(v, 10)}`} aria-hidden="true" />)}
  </div>;
})`
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: var(--dark);
  border: .125rem var(--inset);
  padding: .125rem;
  > *:not(:last-child) {
    margin-right: 3px;
  }
`;

export default Display;