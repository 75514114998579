import { useContext } from 'react';
import { levels } from '../config';
import Context  from '../context';
import styled from 'styled-components';

export default styled(props => {
  const { dispatch } = useContext(Context);
  return <ul {...props}>
    <li>
      <button>Game</button>
      <ul>
        {Object.entries(levels).map(([k, v]) => <li key={k}>
          <button onClick={() => dispatch({type: 'setLevel', level: k})}>{v.label}</button>
        </li>)}
      </ul>
    </li>
    <li>
      <a href="https://en.wikipedia.org/wiki/Minesweeper_(video_game)" target="_blank" rel="noreferrer">Help</a>
    </li>
  </ul>;
})`
  display: flex;
  height: 2rem;
  li {
    &, button, a {
      font-size: .875rem;
      display: flex;
      flex-direction: column;
      position: relative;
    }
    button, a {
      align-items: flex-start;
      padding: .5rem;
    }
    ul {
      display: none;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      margin-top: -1px;
    }
    button:hover {
      background: var(--dark-blue);
      color: var(--light);
    }
  }
  > li:hover {
    &, ul {
      background: var(--light);
    }
    button, a {
      z-index: 2;
    }
    ul {
      display: block;
    }
  }
`;